<div class="bg-footer m-t-20">
  <div class="footer-section">
    <div *ngIf="showFooterInfo">

      <div fxLayout.gt-xs="row" fxLayout.xs="column">

        <div class="p-h-10 m-t-20" fxFlex.xs="55">
          <div class="m-v-15" fxLayoutAlign="start start">
            <div (click)="showHowTo = !showHowTo" ngClass.gt-sm="cursor mat-body-1 f-600" ngClass.xs="mat-body-1">로핀은 법률상담 등 직접적인 법률서비스를 제공하지 않으며 변호사법 및 관련 규정을 준수하고 있습니다.
            </div>
            <mat-icon (click)="showHowTo = !showHowTo" class="cursor m-l-5">arrow_drop_down</mat-icon>
          </div>

          <mat-accordion hideToggle>
            <mat-expansion-panel [expanded]="showHowTo" class="bg-gray-light">
              <div class="border-b-dashed p-b-10 m-b-20">
                <p class="lh-1-8">
                  로핀은 전문가회원 (변호사·법무사)의 사무관리 시스템만을 제공할 뿐, 전문가회원이 개인회원(의뢰인)에게 제공하는 상담정보와 견적서 및 일반인의 전문가 선택 등에 어떠한
                  관여도 하지 않습니다. 모든 전문가 업무는 전문가회원이 직접 작성한 정보로서 로핀은 법률 서비스 및 업무 당사자가 아닙니다.
                </p>
                <p class="lh-1-8 p-v-10">
                  전문가회원의 등록정보 (변호사등록증 · 법무사등록증 · 배상책임보험 정보)는 특정 개인정보를 제외하고 수정없이 표시하고 있습니다.
                  전문가회원이 직접 제공한 정보는 무단으로 복제, 편집, 전시, 배포 등에 이용할 수 없습니다.
                </p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="mat-caption c-gray" fxLayout="column" fxLayoutGap="3px" fxShow fxHide.gt-xs>
            <div><span class="f-600" routerLink="/introduce/company">(주) 로얼라이언스</span> ㅣ 대표 김대훈 ㅣ 사업자번호 522-81-00931</div>
            <div>통신판매번호 2018-서울강남-00730</div>
            <div>개인정보관리책임자 김대훈ㅣ분쟁조정기관표시 전자거래분쟁조정위원회</div>
            <div>서울시 강남구 테헤란로103길 6 한진빌딩 405호</div>
            <div>lawfin&#64;lawalliance.co.kr ㅣ 전화 02-555-0324</div>
          </div>
          <div class="mat-caption c-gray" fxLayout="column" fxLayoutGap="3px" fxShow.gt-xs fxHide>
            <div><span class="f-600" routerLink="/introduce/company">(주) 로얼라이언스</span> ㅣ 대표 김대훈 ㅣ 사업자번호 522-81-00931 ㅣ 통신판매번호 2018-서울강남-00730</div>
            <div>개인정보관리책임자 김대훈ㅣ분쟁조정기관표시 전자거래분쟁조정위원회</div>
            <div>서울시 강남구 테헤란로103길 6 한진빌딩 405호 ㅣ lawfin&#64;lawalliance.co.kr ㅣ 전화 02-555-0324</div>
          </div>

          <div class="p-v-10 m-v-20 mat-body f-12 f-600" ngClass.xs="text-center">
            <span class="cursor" routerLink="/terms">이용약관 &nbsp;ㅣ&nbsp;개인정보 취급·처리 방침</span>&nbsp;ㅣ&nbsp;
            <span
              matTooltip="본 웹사이트에 게시된 이메일 주소가 전자우편 수집프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며 이를 위반 시 ‘정보통신망 이용촉진 및 정보보호등에 관한 법률’ 에 의해 처벌 받을 수 있습니다. -게시일 : 2018. 02. 01-"
              matTooltipPosition="above">이메일 무단수집거부</span>
          </div>

          <div class="p-v-10 p-l-3 m-v-20 f-12 f-600" ngClass.xs="text-center">
            <span><img src="assets/img/bi/lawfin-logo-text-regist.png" class="img-size-14"></span>
            <span class="cursor p-l-5">은 로얼라이언스의 등록상표입니다.</span>
          </div>
        </div>

        <div fxFlex.xs="45">
          <div *ngIf="!isNativeApp" class="m-v-20" fxLayout="row" fxLayoutAlign="center start"
               fxLayoutAlign.gt-xs="end start" fxLayoutGap="15px">
            <div *ngIf="showAppStoreLink" fxFlex fxFlex.gt-xs="140px" fxFlex.xs="38">
              <img (click)="openAppStore()" [src]="appStoreSrc" alt="AppStore Link" class="store-image"/>
            </div>

            <div *ngIf="showPlayStoreLink" fxFlex fxFlex.gt-xs="140px" fxFlex.xs="38">
              <img (click)="openGooglePlay()" [src]="googlePlaySrc" alt="GooglePlay Link" class="store-image"/>
            </div>
          </div>
        </div>


      </div>

    </div>

    <div [ngClass]="{'m-t-10': !showFooterInfo}" class="mat-body p-h-10" ngClass.xs="text-center">
      Copyright © 2015 - {{currentYear}} 로핀 <span>LAWFIN<span class="f-18">&reg;</span></span> <span class="p-l-10" fxHide fxShow.gt-sm>LawAlliance Co.,Ltd</span>
    </div>
  </div>
</div>
