<div fxLayout="column" fxLayoutGap="15px">
  <div *ngIf="user" class="bg-gray-light p-b-10" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
    <div class="p-t-20" fxLayoutAlign="center center">
      <img [src]="user.picture" alt="사용자 사진" class="picture">
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="mat-subheading-2 m-b-0">{{user.nickname}}</div>
      <div class="mat-caption">{{user.username}}</div>
    </div>
    <div fxLayoutGap="20px">
      <button fxFlex class="button-mini f-600 p-t-10 p-v-13 bg-gray-deep"  mat-raised-button routerLink="/my-home"><span class="p-h-20">마이 로팀</span></button>
      <button fxFlex class="button-mini f-600 p-t-10 p-v-13" mat-stroked-button routerLink="/user-modify"><span class="p-h-20">정보변경</span>
      </button>
    </div>
  </div>
  <mat-accordion>
    <mat-nav-list class="p-t-0">
      <div class="p-h-20 p-v-15" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="3px">
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/applications">상담요청</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/case-selector">견적서작성</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/briefcases">사건관리</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/estimates">상담내역</div>
      </div>
    </mat-nav-list>
    <mat-nav-list class="p-t-0 p-b-15">
      <div class="p-h-20 p-v-15" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="3px">
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/fee-tables">상담관리</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/certification/list">내용증명</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/my-home/firm-experts">소속원설정</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/my-home/firm-info">전문가정보</div>
      </div>
    </mat-nav-list>

    <mat-expansion-panel>
      <mat-expansion-panel-header>상담 관리</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item routerLink="/applications">
          상담 요청
        </mat-list-item>
        <mat-list-item routerLink="/case-selector">견적서 신규 작성</mat-list-item>
        <mat-list-item routerLink="/estimates">상담내역</mat-list-item>
        <mat-list-item routerLink="/cost" fxHide>견적서 임시저장함</mat-list-item>
        <mat-list-item routerLink="/fee-tables">보수표 설정</mat-list-item>
        <mat-list-item fxHide routerLink="/auto-estimates">자동견적서 설정</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="/briefcases"><span class="f-600 f-13">사건관리</span></mat-list-item>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-expansion-panel>
      <mat-expansion-panel-header>내용증명</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item routerLink="/certification">자동작성</mat-list-item>
        <mat-list-item routerLink="/certification/list">작성 리스트</mat-list-item>
        <mat-list-item routerLink="/certification/info">서비스 안내</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>

    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="/consulting"><span class="f-600">사건사례</span></mat-list-item>
    </mat-nav-list>
    <mat-divider></mat-divider>

    <mat-expansion-panel>
      <mat-expansion-panel-header>전문가 운영관리</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item routerLink="/my-home/my-statistics">나의 수임사건</mat-list-item>
        <mat-list-item routerLink="/my-home/firm-statistics">전문가 통계</mat-list-item>
        <mat-list-item routerLink="/my-home/management/distribution">파트너 수익배분설정</mat-list-item>
        <mat-list-item *ngIf="user.solicitorOffice" routerLink="/my-home/case-record">사건부 관리</mat-list-item>
        <mat-list-item routerLink="/my-home/firm-experts">소속원 설정</mat-list-item>
        <mat-list-item routerLink="/my-home/firm-info">전문가 정보관리</mat-list-item>
        <mat-list-item routerLink="/address-book">주소록</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel>
      <mat-expansion-panel-header>서비스 이용관리</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item fxHide routerLink="/my-home/firm-pay-statement">결제내역</mat-list-item>
        <mat-list-item fxHide routerLink="/my-home/cash-statement">사무소 캐시</mat-list-item>
        <mat-list-item fxHide routerLink="/my-home/auto-estimate-ticket">자동견적 발행권</mat-list-item>
        <mat-list-item fxHide routerLink="/my-home/house-coupon-statement">쿠폰함</mat-list-item>
        <mat-list-item routerLink="/my-home/switch">서비스 계정전환</mat-list-item>
        <mat-list-item routerLink="/contract">전문가사무소 추가등록</mat-list-item>
        <mat-list-item routerLink="apply-firm">소속원 신청</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel>
      <mat-expansion-panel-header>전문가 추가</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item routerLink="/contract">전문가사무소 추가등록</mat-list-item>
        <mat-list-item routerLink="apply-firm">소속원 신청</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-nav-list class="p-t-10 row-cursor">
      <mat-list-item routerLink="/introduce"><span class="f-600">일반인 서비스 안내</span></mat-list-item>
      <mat-list-item routerLink="/introduce/firm"><span class="f-600">전문가 서비스 안내</span></mat-list-item>
      <mat-list-item routerLink="/guide"><span class="f-600">이용방법</span></mat-list-item>
      <mat-list-item fxHide routerLink="/event"><span class="f-600">이벤트</span></mat-list-item>
      <mat-list-item routerLink="/notice"><span class="f-600">공지사항</span></mat-list-item>
      <mat-list-item routerLink="/update"><span class="f-600">업데이트 안내</span></mat-list-item>
      <mat-list-item (click)="clickQuestion()"><span class="f-600">1:1 고객센터</span></mat-list-item>
      <mat-list-item routerLink="/introduce/company"><span class="f-600">회사소개</span></mat-list-item>
    </mat-nav-list>

    <mat-divider></mat-divider>
    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="/firms/manual"><span class="f-600 c-black">전문가 서비스 메뉴얼</span></mat-list-item>
    </mat-nav-list>
  </mat-accordion>
</div>
