<div class="info-wrapper">
  <app-swiper>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-01.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">무료 법무관리 서비스</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              변호사 &middot; 법무사 회원은 전문가서비스를 무료로 이용하실 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-06.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">대법원 사건정보 자동 확인</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              진행정보가 사건관리와 캘린더에 자동 등록되고 일정 알림을 받을 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-05.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">의뢰인 사건진행 안내</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              사건관리만으로 의뢰인에게 사건진행상황을 자동으로 안내할 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-12.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">사건 견적 작성</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              사안별 견적설정으로 소속원 누구나 빠르게 견적서를 작성할 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-11.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">사무소 공유 캘린더</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              대법원 기일정보가 자동 등록되고 소속원별 일정을 공유 할 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-07.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">소속원 사건관리 및 공유</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              소속원이 공동으로 사건을 관리 할 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-08.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">전문가 운영 정보 관리</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              사무소 운영비용과 미수금 현황을 확인하고 관리 할 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-10.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">전문가 사무소 운영 통계</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-1">
              수익현황부터 수임사건 현황까지 다양한 통계를 확인 할 수 있습니다.
            </div>
            <div class="mat-body-1 f-600 p-t-20" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
    <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">

        <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img ngClass.gt-xs="img-size-120 p-b-20" ngClass.xs="img-size-90 p-b-15"
                 src="assets/img/service/client-info-main-13.png">
          </div>
          <div ngClass.gt-xs="p-h-20" ngClass.xs="p-h-10">
            <div ngClass.gt-sm="mat-title ff-sc-6" ngClass.xs="mat-subheading-2 ff-sc-6">견적서 자동발행</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-2">
              <span class="f-600">견적상담 업무를 자동화하여 24시간 자동발행</span> 할 수 있습니다.
            </div>
            <div class="mat-body-1 f-600" routerLink="/introduce/firm">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
  </app-swiper>
</div>
