import {BrowserModule} from '@angular/platform-browser';
import {Inject, NgModule, PLATFORM_ID} from '@angular/core';
import {AppComponent} from './app.component';
import {NavModule} from './nav/nav.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BasicAuthInterceptor} from './_interceptors/basic-auth.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {SpinnerModule} from './shared/spinner/spinner.module';
import {isPlatformBrowser} from '@angular/common';
import {NativeAppService} from './_services/native-app.service';
import {UserService} from './domain/user/user.service';
import {DirectiveModule} from './_directive/directive.module';
import {environment} from '../environments/environment';
import {COMPOSITION_BUFFER_MODE} from '@angular/forms';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {IntroModule} from './page/intro/intro.module';
import {BottomButtonSheetModule} from './shared/bottom-button-sheet/bottom-button-sheet.module';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {LIGHTBOX_CONFIG, LightboxConfig} from 'ng-gallery/lightbox';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'lawfin'}),
    BrowserAnimationsModule,
    LoggerModule.forRoot({level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG}),
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule.withConfig({
      ssrObserveBreakpoints: ['xs', 'gt-xs'],
      useColumnBasisZero: false,
      printWithBreakpoints: ['gt-xs']
    }),
    IntroModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatDialogModule,
    SpinnerModule,
    NavModule,
    DirectiveModule,
    BottomButtonSheetModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    {provide: 'API_HOST', useValue: environment.apiUrl},
    {provide: COMPOSITION_BUFFER_MODE, useValue: false},
    {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {horizontalPosition: 'center', verticalPosition: 'top', duration: 3000}},
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        exitAnimationTime: 1000
      } as LightboxConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId,
              private userService: UserService,
              private matDialog: MatDialog,
              private nativeAppService: NativeAppService) {
    if (isPlatformBrowser(this.platformId)) {
      Object.keys(sessionStorage).forEach(key => {
        if (key !== 'currentUser' && key !== 'previous') {
          sessionStorage.removeItem(key);
        }
      });

      this.matDialog.afterOpened.subscribe(() => {
        this.nativeAppService.toggleAndroidRefresh(false);
      });

      this.matDialog.afterAllClosed.subscribe(() => {
        this.nativeAppService.toggleAndroidRefresh(true);
      });

      window['sendPushInfo'] = (fcmKey: string, receiveNotification: string) => {
        this.nativeAppService.sendPushInfo(fcmKey, receiveNotification);
      };

      window['setDevice'] = (osName: string) => {
        this.nativeAppService.setOS(osName);
        return true;
      };
    }
  }
}
