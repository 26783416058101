<app-swiper>
  <swiper-slide><div class="banner bg-gray-light">
      <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">
        <div fxLayout="column" ngClass.xs="p-h-10">
          <div ngClass.xs="p-h-5">
            <img alt="사건 예상견적을 확인해보세요!" class="img-size-90 p-b-15"
                 ngClass.gt-xs="img-size-120 p-b-20" src="assets/img/service/client-info-main-03.png">
          </div>

          <div class="p-h-10" ngClass.gt-xs="p-h-20">
            <div class="ff-sc-6" ngClass.gt-xs="mat-title" ngClass.xs="mat-subheading-2">사건 예상견적을 확인해보세요 !</div>

            <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-2">
              소송 및 등기 사건의 예상견적을 확인 할 수 있습니다.
            </div>

            <div class="mat-body-2 m-t-20" routerLink="/case-selector">
              <u>자세히 보기</u>
            </div>
          </div>
        </div>
      </section>
    </div></swiper-slide>
  <swiper-slide><div class="banner bg-gray-light">
    <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">
      <div fxLayout="column" ngClass.xs="p-h-10">
        <div ngClass.xs="p-h-5">
          <img alt="내용증명을 무료로 작성하세요 !" class="img-size-90 p-b-15"
               ngClass.gt-xs="img-size-120 p-b-20" src="assets/img/service/client-info-main-04.png">
        </div>

        <div class="p-h-10" ngClass.gt-xs="p-h-20">
          <div class="ff-sc-6" ngClass.gt-xs="mat-title" ngClass.xs="mat-subheading-2">내용증명을 무료로 작성하세요 !</div>

          <div ngClass.gt-xs="mat-subheading-1" ngClass.xs="mat-body-2">
            대여금에서 임대차보증금까지 자동작성 할 수 있습니다.
          </div>

          <div class="mat-body-2 p-t-20" routerLink="/certification/info">
            <u>자세히 보기</u>
          </div>
        </div>
      </div>
    </section>
  </div></swiper-slide>
  <swiper-slide><div class="banner bg-gray-light">
    <section class="cursor p-t-10 banner" ngClass.xs="p-t-2">
      <div fxLayout="column" ngClass.xs="p-h-10">
        <div ngClass.xs="p-h-5">
          <img alt="사건진행현황을 확인하세요." class="img-size-90 p-b-15"
               ngClass.gt-xs="img-size-120 p-b-20" src="assets/img/service/client-info-main-05.png">
        </div>

        <div class="p-h-10" ngClass.gt-xs="p-h-20">
          <div class="ff-sc-6" ngClass.gt-xs="mat-title" ngClass.xs="mat-subheading-2">사건진행현황을 확인하세요.</div>

          <div class="mat-body-2" ngClass.gt-xs="mat-subheading-1">
            위임 전문가로부터 사건현황 및 진행율을 확인 할 수 있습니다.
          </div>
          <div class="mat-body-2 p-t-20" routerLink="/introduce">
            <u>자세히 보기</u>
          </div>
        </div>
      </div>
    </section>
  </div></swiper-slide>
</app-swiper>
