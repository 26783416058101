<button (menuOpened)="openMenu()" [matMenuTriggerFor]="userMenu" class="lh-4-0" mat-button ngClass.xs="p-h-2">
  <span *ngIf="!user.expert" class="badge-kr bg-gray-deep f-11">일반</span>
  <span *ngIf="user.expert" class="badge-kr bg-primary f-11">전문가</span>
  <span class="f-12" fxHide fxShow.gt-xs>{{user.expert ? currentFirmName : user.nickname}}</span>
  <mat-icon *ngIf="user.expert" ngClass.gt-sm="c-gray" ngClass.xs="c-gray">arrow_drop_down</mat-icon>
  <mat-icon *ngIf="!user.expert" ngClass.gt-sm="c-gray" ngClass.xs="c-gray">arrow_drop_down</mat-icon>
</button>

<mat-menu #userMenu="matMenu" xPosition="before" class="p-h-5">
  <div (click)="switchFirm(firm.id)" *ngFor="let firm of firms" class="p-h-10" fxLayout="row"
       fxLayoutAlign="space-between center" mat-menu-item>
    <div class="badge-kr bg-primary f-11">전문가</div>
    <div>{{firm.name}}</div>
  </div>

  <mat-divider *ngIf="firms.length > 0" class="m-v-5"></mat-divider>

  <div (click)="switchFirm(null)" *ngIf="user.expert"
       class="p-h-10" fxLayout="row" fxLayoutAlign="space-between center" mat-menu-item>
    <div class="badge-kr bg-gray-deep f-11">일반인</div>
    <div>{{user.nickname}}</div>
  </div>

  <mat-divider *ngIf="user.expert" class="m-v-7"></mat-divider>

  <div class="p-10 cursor" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
    <div class="menu-mini-button" routerLink="/my-home/switch"><u>계정관리</u></div>
    <div (click)="logout()" class="menu-mini-button">로그아웃</div>
  </div>
</mat-menu>
