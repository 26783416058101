<div fxLayout="column" fxLayoutGap="15px">
  <div class="bg-gray-light p-b-10" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
    <div class="p-t-20" fxLayoutAlign="center center">
      <img [src]="user.picture" alt="사용자 사진" class="picture">
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="mat-subheading-2 p-b-5">{{user.nickname}}</div>
      <div class="mat-caption">{{user.username}}</div>
    </div>
    <div fxLayoutGap="10px">
      <button class="button-mini f-600" color="primary" mat-raised-button routerLink="/my-home"><span class="p-h-25">마이 홈</span>
      </button>
      <button class="button-mini f-600" mat-stroked-button routerLink="/user-modify"><span class="p-h-20">정보변경</span>
      </button>
    </div>
  </div>
  <mat-accordion>
    <mat-nav-list class="p-t-0">
      <div class="p-h-20 p-b-7" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="3px">
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="applications">법률상담</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/certification/list">내용증명</div>
        <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="briefcases">사건진행</div>
      </div>
    </mat-nav-list>

    <mat-expansion-panel>
      <mat-expansion-panel-header>법률 상담</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item routerLink="/case-selector">
          무료 상담 신청
        </mat-list-item>
        <mat-list-item routerLink="applications">상담 내역</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="briefcases"><span class="f-600 f-13">사건 진행상황</span></mat-list-item>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-expansion-panel>
      <mat-expansion-panel-header>내용증명</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item routerLink="/certification">자동작성</mat-list-item>
        <mat-list-item routerLink="/certification/list">작성 리스트</mat-list-item>
        <mat-list-item routerLink="/certification/info">서비스 안내</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>

    <mat-divider></mat-divider>
    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="/consulting"><span class="f-600">사건사례</span></mat-list-item>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-expansion-panel>
      <mat-expansion-panel-header>서비스 이용관리</mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <mat-list-item fxHide routerLink="/my-home/user-pay-statement">결제내역</mat-list-item>
        <mat-list-item fxHide routerLink="/my-home/house-coupon-statement">쿠폰함</mat-list-item>
        <mat-list-item routerLink="/my-home/switch">서비스 계정전환</mat-list-item>
        <mat-list-item routerLink="/contract">전문가사무소 등록</mat-list-item>
        <mat-list-item routerLink="apply-firm">소속원 신청</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-nav-list class="p-t-10 row-cursor">
      <mat-list-item routerLink="/introduce"><span class="f-600">일반인 서비스 안내</span></mat-list-item>
      <mat-list-item routerLink="/introduce/firm"><span class="f-600">전문가 서비스 안내</span></mat-list-item>
      <mat-list-item routerLink="/guide"><span class="f-600">이용방법</span></mat-list-item>
      <mat-list-item routerLink="/event"><span class="f-600">이벤트</span></mat-list-item>
      <mat-list-item routerLink="/notice"><span class="f-600">공지사항</span></mat-list-item>
      <mat-list-item routerLink="/update"><span class="f-600">업데이트 안내</span></mat-list-item>
      <mat-list-item (click)="clickQuestion()"><span class="f-600">1:1 고객센터</span></mat-list-item>
      <mat-list-item routerLink="/introduce/company"><span class="f-600">회사소개</span></mat-list-item>
    </mat-nav-list>

    <mat-divider></mat-divider>
    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="/firms/manual"><span class="f-600 c-primary">전문가 서비스 메뉴얼</span></mat-list-item>
    </mat-nav-list>
  </mat-accordion>
</div>
