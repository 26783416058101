<div fxLayout="column">
  <div class="bg-gray-light" fxLayout="column" fxLayoutAlign="center center">
    <div class="p-20" fxLayoutAlign="center center">
      <img src="/assets/img/bi/lawfin-bi-xs.png" class="img-size-50">
    </div>
    <div fxLayoutGap="20px" class="p-v-20">
      <div fxLayoutGap="20px">
        <button fxFlex class="button-mini f-600 p-t-10 p-v-13 bg-gray-deep"  mat-raised-button routerLink="/join"><span class="p-h-20">회원가입</span></button>
        <button fxFlex class="button-mini f-600 p-t-10 p-v-13" mat-stroked-button routerLink="/login"><span class="p-h-20">로그인</span>
        </button>
      </div>
    </div>

  </div>

  <mat-divider></mat-divider>

  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <span class="f-600">일반인 서비스</span>
      </mat-expansion-panel-header>
      <mat-nav-list class="p-t-0 p-b-20">
        <div class="p-h-20" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="3px">
          <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/case-selector">상담신청</div>
          <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/certification">내용증명</div>
        </div>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <span class="f-600">전문가 서비스</span>
      </mat-expansion-panel-header>
      <mat-nav-list class="p-t-0">
        <div class="p-h-20 p-b-7" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="3px">
          <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/contract">상담사건</div>
          <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/contract">보수표관리</div>
          <div class="text-circle-70 bg-gray-light f-12 f-600" fxHide routerLink="/contract">자동견적서</div>
          <div class="text-circle-70 bg-gray-light f-12 f-600" routerLink="/contract">사건관리</div>
        </div>
      </mat-nav-list>
      <mat-nav-list class="p-t-15 row-cursor">
        <mat-list-item routerLink="/contract">의뢰인 관리</mat-list-item>
        <mat-list-item routerLink="/contract">파트너 수익배분 관리</mat-list-item>
        <mat-list-item routerLink="/contract">운영통계</mat-list-item>
        <mat-list-item class="f-600" routerLink="/contract">전문가 등록</mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="/consulting"><span class="f-600">사건사례</span></mat-list-item>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list class="p-t-10 row-cursor">
      <mat-list-item routerLink="/introduce"><span class="f-600">일반인 서비스 안내</span></mat-list-item>
      <mat-list-item routerLink="/introduce/firm"><span class="f-600">전문가 서비스 안내</span></mat-list-item>
      <mat-list-item routerLink="/guide"><span class="f-600">이용방법</span></mat-list-item>
      <mat-list-item fxHide routerLink="/event"><span class="f-600">이벤트</span></mat-list-item>
      <mat-list-item routerLink="/notice"><span class="f-600">공지사항</span></mat-list-item>
      <mat-list-item routerLink="/update"><span class="f-600">업데이트 안내</span></mat-list-item>
      <mat-list-item routerLink="/question"><span class="f-600">1:1 고객센터</span></mat-list-item>
      <mat-list-item routerLink="/introduce/company"><span class="f-600">회사소개</span></mat-list-item>
    </mat-nav-list>

    <mat-divider></mat-divider>
    <mat-nav-list class="p-v-5 row-cursor">
      <mat-list-item routerLink="/firms/manual"><span class="f-600">전문가 서비스 메뉴얼</span></mat-list-item>
    </mat-nav-list>
  </mat-accordion>
</div>
