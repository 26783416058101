<div fxLayout="row">
  <button mat-button routerLink="/consulting">사건사례</button>
  <button (click)="clickMenuItem('사건 관리','/briefcases')" mat-button>사건 진행 안내</button>
  <button [matMenuTriggerFor]="costMenu" mat-button>
    <span>무료 법률상담</span>
    <mat-icon class="c-gray">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #costMenu="matMenu">
    <button mat-menu-item routerLink="/case-selector">상담 신청</button>
    <button (click)="clickMenuItem('견적신청 사건', '/applications')" mat-menu-item>상담 내역</button>
  </mat-menu>
  <button [matMenuTriggerFor]="certificationMenu" mat-button>
    무료 내용증명
    <mat-icon class="c-gray">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #certificationMenu="matMenu" xPosition="before">
    <button mat-menu-item routerLink="/certification">신규 작성</button>
    <button (click)="clickMenuItem('내용증명 작성 리스트', '/certification/list')" mat-menu-item>작성 내역</button>
    <button mat-menu-item routerLink="/certification/info">이용 방법</button>
  </mat-menu>
</div>
