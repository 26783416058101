<app-intro-client-banner></app-intro-client-banner>

<section class="wide-section bg-gray-fa" ngClass.gt-xs="p-t-20">
  <div ngClass.xs="p-h-10">
    <div class="mat-shadow-d p-h-10 p-v-25 bg-white">
      <div fxLayout="row wrap">
        <div class="p-v-8" fxFlex="33" fxFlex.gt-xs="20">
          <a (click)="clickLink('/my-home')" class="hover" fxLayout="column" fxLayoutAlign="center center">
            <img alt="마이 홈" class="img-size-50" ngClass.gt-xs="img-size-60"
                 src="assets/img/service/main-icon-myhome.png">
            <div>마이 홈</div>
          </a>
        </div>

        <div class="p-v-8" fxFlex="33" fxFlex.gt-xs="20">
          <a (click)="clickLink('/briefcases')" class="hover" fxLayout="column" fxLayoutAlign="center center">
            <img alt="사건진행 현황" ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-brief-state.png">
            <div>사건진행 현황</div>
          </a>
        </div>

        <div class="p-v-8" fxFlex="33" fxFlex.gt-xs="20">
          <a (click)="clickLink('/case-selector')" class="hover" fxLayout="column" fxLayoutAlign="center center">
            <img alt="상담신청" class="img-size-50"
                 ngClass.gt-xs="img-size-60" src="assets/img/service/main-icon-estimate-client-gray.png">
            <div>상담신청</div>
          </a>
        </div>

        <div class="p-v-8" fxFlex="33" fxFlex.gt-xs="20">
          <a (click)="clickLink('/certification')" class="hover" fxLayout="column" fxLayoutAlign="center center">
            <img alt="내용증명" class="img-size-50"
                 ngClass.gt-xs="img-size-60" src="assets/img/service/main-icon-certification-client-gray.png">
            <div>내용증명</div>
          </a>
        </div>

        <div class="p-v-8" fxFlex="33" fxFlex.gt-xs="20">
          <div (click)="clickLink('/consulting')" class="hover" fxLayout="column" fxLayoutAlign="center center">
            <img alt="사건사례" class="img-size-50"
                 ngClass.gt-xs="img-size-60" src="assets/img/service/main-icon-counsel-client-gray.png">
            <div>사건사례</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-v-30" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="30px">
    <div fxFlex.gt-xs="50" fxFlexOrder="1" fxFlexOrder.gt-xs="2">
      <div class="bg-white p-h-15 p-v-20" ngClass.gt-xs="p-h-20 p-v-30" routerLink="/case-selector">
        <div class="p-b-20" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap.gt-xs="20px">
          <div ngClass.gt-sm="p-h-20">
            <div class="mat-subheading-1 ff-sc-6" ngClass.gt-sm="mat-subheading-2">
              법률사건 예상견적서
            </div>

            <div class="mat-body-2 p-b-25" ngClass.gt-xs="mat-body-1">
              공과금과 변호사 &middot; 법무사 예상 보수액을 미리 확인하세요.
            </div>
          </div>

          <div>
            <img alt="법률사건 예상견적서" class="img-size-100" ngClass.gt-xs="img-size-110"
                 src="assets/img/service/main-icon-estimate-client.png">
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="5px" ngClass.gt-xs="p-h-15">
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">소송사건</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">가사</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">형사</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">행정</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">신청사건</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">민사집행</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">회생파산</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">공탁</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">부동산등기</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="18">법인등기</div>
        </div>
      </div>
    </div>

    <div fxFlex.gt-xs="50" fxFlexOrder="2" fxFlexOrder.gt-xs="1" fxLayout="row">
      <div class="bg-white p-h-15 p-v-20" fxFlex ngClass.gt-xs="p-h-20 p-v-30" routerLink="/certification">
        <div class="p-b-20" fxLayout="row" fxLayoutAlign="space-between center">
          <div ngClass.gt-xs="p-h-20">
            <div class="mat-subheading-1" ngClass.gt-xs="mat-subheading-2">
              내용증명 무료 자동작성
            </div>
            <div class="mat-body-2 p-b-25" ngClass.gt-xs="mat-body-1">
              쉽고 빠르게 무료로 내용증명을 보내세요.
            </div>
          </div>

          <div>
            <img alt="내용증명 무료 자동작성" class="img-size-100" ngClass.gt-xs="img-size-110"
                 src="assets/img/service/main-icon-certification-client.png">
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="5px" ngClass.gt-xs="p-h-15">
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="19">금전 채권</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="19">부동산매매</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="19">임대차계약</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="19">상품거래</div>
          <div class="icon-circle-wrapper-xs m-b-5" fxFlex="23" fxFlex.gt-xs="19">손해배상</div>
        </div>
      </div>
    </div>
  </div>

  <div ngClass.gt-xs="m-t-50 m-b-20" ngClass.xs="m-v-30">
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="20px" fxLayoutGap.gt-xs="30px">
      <div fxFlex.gt-xs="50">
        <div ngClass.xs="p-h-10">
          <div class="bg-white p-20 cursor" ngClass.xs="brd-15 p-v-35">
            <div fxLayout="row" fxLayoutAlign="space-between center" routerLink="/contract">
              <div fxLayout="column">
                <div class="mat-subheading-2 p-b-7 ff-sc-5">전문가 사무소 등록</div>
                <div class="mat-body-2">
                  등록 즉시 전문가서비스를 무료로 이용하실 수 있습니다.
                </div>
              </div>
              <mat-icon class="mat-icon-size-45 c-primary">sentiment_satisfied_alt</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex.gt-xs="50">
        <div ngClass.xs="p-h-10" routerLink="/apply-firm">
          <div class="bg-white p-20 cursor" ngClass.xs="brd-15 p-v-35">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" routerLink="/contract">
              <div fxLayout="column">
                <div class="mat-subheading-2 f-600 p-b-7 ff-sc-5">소속원 등록</div>
                <div ngClass.gt-xs="mat-body-2">소속원 수의 제한없이 사무소에 등록할 수 있습니다.</div>
              </div>
              <mat-icon class="mat-icon-size-45 c-primary">people</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gray-checkteam cursor m-t-40">
    <a href="https://checkteam.co.kr" target="_blank">
      <div class="p-t-10">
        <div class="title-chip">체크팀 바로가기</div>
      </div>

      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-around center" ngClass.xs="p-h-15 m-t-10">
        <div class="p-b-20" fxFlex.gt-xs="55" ngClass.gt-xs="p-l-20" ngClass.xs="text-center">
          <div>
            <div ngClass.gt-xs="mat-subheading-2 f-600 ff-sc-4 m-b-5"
                 ngClass.xs="mat-subheading-1 p-t-10 ff-sc-4 m-b-5">
              전세 &middot; 소유 &middot; 담보 부동산의
            </div>
            <div class="ff-sc-5 lh-1-7" ngClass.gt-xs="mat-title" ngClass.xs="mat-subheading-2">
              위험한 등기정보를 누구나 확인할 수 있습니다!
            </div>
            <div ngClass.gt-xs="mat-body-2 ff-sc-4 m-b-5" ngClass.xs="mat-body-1 f-600 ff-sc-4 m-b-5">
              부동산등기변경이 확인되면 알림으로 안내됩니다.
            </div>
            <div class="p-t-20" routerLink="/info/my-house">
              <div class="p-b-10" fxLayout="column">
                <div fxLayout.xs="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign.xs="center center"
                     fxLayoutGap="5px">
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">임대보증금</div>
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">부동산계약</div>
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">가맹점</div>
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">채권담보</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-b-30" fxLayout="column" fxLayoutAlign="center center" ngClass.xs="m-t-20">
          <img alt="체크팀 링크" class="img-size-85p" ngClass.gt-xs="img-size-60p" src="assets/img/gif/main-notice-v03.gif">
        </div>
      </div>
    </a>
  </div>

  <div class="m-v-30" ngClass.gt-xs="m-b-20 p-h-20">
    <div fxFlex fxFlex.gt-xs="40" fxFlexOffset.gt-xs="60">
      <div ngClass.xs="p-h-20">
        <app-intro-report></app-intro-report>
      </div>
    </div>
  </div>
</section>
