<mat-toolbar class="mat-elevation-z1" fxLayout="column" fxLayoutAlign="center center">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center" ngClass.xs="p-r-5">
    <div class="cursor" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" routerLink="/">
      <div fxShow fxHide.gt-xs class="p-t-5">
        <img [src]="mobileLogoSrc" alt="LawFin BI" class="mobile-logo"/>
      </div>
      <div fxHide fxShow.gt-xs>
        <img src="assets/img/bi/lawfin-logo.png" alt="LawFin BI" class="pc-logo p-l-10"/>
      </div>
      <div fxHide fxShow.gt-xs>
        <img src="assets/img/bi/text-logo.png" alt="LawFin BI" class="text-logo"/>
      </div>
      <div *ngIf="pageRole === 'EXPERT'" class="f-13 p-t-5 c-gray ff-sc-8" fxHide fxShow.gt-xs>EXPERT</div>

      <div *ngIf="!user">
        <div *ngIf="showRoleSwitch" fxHide.gt-xs fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" fxShow>
          <button (click)="switchRole('CLIENT')" [color]="pageRole === 'CLIENT' ? 'primary' : null"
                  class="f-600 f-11 m-w-50 m-l-3 mat-shadow-a" mat-button mat-raised-button>일반인
          </button>
          <button (click)="switchRole('EXPERT')" [color]="pageRole === 'EXPERT' ? 'primary' : null"
                  class="f-600 f-11 m-w-50 mat-shadow-a" mat-button mat-raised-button>전문가
          </button>
        </div>
      </div>
    </div>

    <div fxLayout="row">
      <div fxHide fxLayout="row" fxShow.gt-xs>
        <button [matMenuTriggerFor]="serviceMenu" mat-button>
          서비스 안내
          <mat-icon class="c-gray">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #serviceMenu="matMenu" xPosition="before">
          <button mat-menu-item routerLink="/introduce">일반인 서비스</button>
          <button mat-menu-item routerLink="/introduce/firm">전문가 서비스</button>
          <mat-divider></mat-divider>
          <button mat-menu-item routerLink="/firms/manual">전문가 메뉴얼</button>
          <mat-divider></mat-divider>
          <button fxHide mat-menu-item routerLink="/event">이벤트</button>
          <button mat-menu-item routerLink="/notice">공지사항</button>
          <button mat-menu-item routerLink="/update">업데이트 안내</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="clickQuestion()">1 : 1 고객센터</button>
          <mat-divider></mat-divider>
          <button mat-menu-item routerLink="/introduce/company">회사소개</button>
        </mat-menu>
      </div>

      <button class="bg-gray-light" fxHide fxShow.gt-xs mat-button routerLink="/guide">이용방법</button>

      <button [matMenuTriggerFor]="expertRegist" fxHide fxShow.gt-xs mat-button>
        전문가 등록
        <mat-icon class="c-gray">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #expertRegist="matMenu" xPosition="before">
        <button (click)="clickMenuItem('전문가 등록', '/contract')" mat-menu-item>전문가 신규 등록</button>
        <button (click)="clickMenuItem('소속원 신청', '/apply-firm')" mat-menu-item>소속원 신청</button>
      </mat-menu>

      <div *ngIf="!user" fxLayout="row" fxLayoutGap="10px" class="p-l-10" ngClass.xs="p-r-10">
        <button class="f-12 bg-gray-light" mat-button  mat-raised-button routerLink="/join">회원 가입</button>
        <button class="f-12 bg-gray-light" mat-button  mat-raised-button routerLink="/login">로그인</button>
      </div>

      <div *ngIf="user" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px" fxLayoutGap.xs="15px">
        <app-notification-button></app-notification-button>

        <button (click)="clickExpertMenuItem('캘린더', '/calendar')" *ngIf="user.expert" fxHide.gt-xs fxShow
                mat-icon-button>
          <mat-icon [matBadgeHidden]="user.countTodaySchedules === 0" [matBadge]="user.countTodaySchedules"
                    class="icon-23 c-gray" matBadgeColor="accent"
                    matBadgeSize="small">calendar_today
          </mat-icon>
        </button>

        <button (click)="clickExpertMenuItem('주소록', '/address-book')" *ngIf="user.expert" mat-icon-button>
          <mat-icon class="icon-23 c-gray">account_box</mat-icon>
        </button>

        <button (click)="clickMenuItem('명함첩', '/card-holder')" *ngIf="!user.expert" mat-icon-button>
          <mat-icon class="icon-23 c-gray">account_box</mat-icon>
        </button>

        <button (click)="openRightDrawer()" *ngIf="user.expert" mat-icon-button>
          <mat-icon class="icon-23 c-gray">format_list_bulleted</mat-icon>
        </button>

        <app-switch-service-button [user]="user"></app-switch-service-button>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="p-v-5" fxLayout="row" fxLayoutAlign="space-between center" fxHide fxShow.gt-sm>
    <div *ngIf="user"></div>
    <div *ngIf="!user" fxLayout="row" fxLayoutGap="5px">
      <button (click)="switchRole('CLIENT')" *ngIf="showRoleSwitch" [color]="pageRole === 'CLIENT' ? 'primary' : null"
              class="m-l-2 f-600 f-12 p-h-25 mat-shadow-a button-md"
              mat-raised-button>일반인
      </button>

      <button (click)="switchRole('EXPERT')" *ngIf="showRoleSwitch" [color]="pageRole === 'EXPERT' ? 'primary' : null"
              class="f-12 f-600 p-h-25 mat-shadow-a button-md"
              mat-raised-button>
        전문가
      </button>
    </div>

    <div fxLayout="row">
      <app-client-toolbar *ngIf="pageRole === 'CLIENT'" [user]="user"></app-client-toolbar>
      <app-expert-toolbar *ngIf="pageRole === 'EXPERT'"></app-expert-toolbar>

      <button *ngIf="user" [matMenuTriggerFor]="user.expert ? expertMenu : clientMenu" class="p-h-16" mat-button>
        {{pageRole === 'EXPERT' ? '마이 로팀' : '마이 홈'}}
        <mat-icon class="c-gray">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #clientMenu="matMenu" xPosition="before">
        <button mat-menu-item routerLink="/my-home">마이 홈</button>
<!--        <button mat-menu-item routerLink="/my-home/house-coupon-statement">쿠폰함</button>-->
        <button mat-menu-item routerLink="/my-home/user-pay-statement">결제내역</button>
        <button mat-menu-item routerLink="/contract">전문가 등록</button>
        <button mat-menu-item routerLink="/apply-firm">전문가 소속신청</button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="user-modify/sns">SNS 계정관리</button>
        <button mat-menu-item routerLink="user-modify">정보변경</button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/my-home/switch">서비스 계정</button>
      </mat-menu>

      <mat-menu #expertMenu="matMenu" xPosition="before">
        <button mat-menu-item routerLink="/my-home">마이 로팀</button>
        <button mat-menu-item routerLink="/my-home/my-statistics" fxHide>나의 수임사건</button>
        <button mat-menu-item routerLink="/my-home/management" fxHide>운영 관리</button>
        <button mat-menu-item routerLink="/my-home/firm-statistics" fxHide>운영 통계</button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/my-home/firm-experts">소속원 설정</button>
        <button mat-menu-item routerLink="/my-home/firm-info" fxHide>전문가 정보 관리</button>
        <button mat-menu-item routerLink="/address-book" fxHide>주소록</button>
        <button *ngIf="user && user.solicitorOffice" mat-menu-item routerLink="/my-home/case-record" fxHide>사건부</button>
        <mat-divider></mat-divider>
        <button fxHide mat-menu-item routerLink="/my-home/firm-pay-statement">결제내역</button>
        <button fxHide mat-menu-item routerLink="/my-home/cash-statement">사무소 캐시</button>
        <button fxHide mat-menu-item routerLink="/my-home/auto-estimate-ticket">자동견적서 발행권</button>
        <button fxHide mat-menu-item routerLink="/my-home/house-coupon-statement">쿠폰함</button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/my-home/switch">서비스 계정전환</button>
        <button mat-menu-item routerLink="/contract">전문가 추가등록</button>
        <button mat-menu-item routerLink="/apply-firm">소속원 신청</button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="user-modify">개인정보 변경</button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="toolbar-offset"></div>
